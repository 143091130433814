import { useAuthProps } from 'hooks/useAuth'

// routes
import {
    PATH_ACTION_PLANING,
    PATH_ANALYTICS,
    PATH_AUDIT_PLANING,
    PATH_AUTH,
    PATH_DASHBOARD,
    PATH_INITIATIVES,
    PATH_REPORTS,
    PATH_SETTINGS,
    PATH_SUPERADMIN
} from 'routes/paths'
// components
import { AVAILABLE_REPORTS } from 'constants/reports'
import { getIcon } from 'utils/images'
import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { CompanySettingsProps } from '../../@types/settings'
// ----------------------------------------------------------------------

const ICONS = {
    dashboard: getIcon('ic_dashboard'),
    initiative: getIcon('ic_initiatives'),
    page: getIcon('ic_dashboard'),
    planing: getIcon('ic_planing'),
    settings: getIcon('ic_settings'),
    cpProfile: getIcon('ic_cp_profile'),
    setup: getIcon('ic_setup'),
    logout: getIcon('ic_logout'),
    elements: getIcon('ic_elements'),
    users: getIcon('ic_users'),
    subscription: getIcon('ic_subscription'),
    analytics: getIcon('ic_analytics'),
    audit: getIcon('ic_booking'),
    banking: getIcon('ic_banking'),
    ic_mail: getIcon('ic_mail'),
    ic_integration: getIcon('ic_integration'),
    ic_help: getIcon('ic_help'),
    ic_badget_help: getIcon('ic_badget_help'),
}

const sidebarConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: '',
        items: [
            {
                title: 'Dashboard',
                path: PATH_DASHBOARD.general.dashboard,
                icon: ICONS.dashboard,
            },
            {
                title: 'Initiatives',
                allowed: ({ isSuperAdmin, isAuthenticated }: useAuthProps) => isAuthenticated && !isSuperAdmin,
                path: PATH_INITIATIVES.root,
                icon: ICONS.initiative,
            },
            {
                title: 'Reports',
                path: PATH_REPORTS.root,
                allowed: ({ isSuperAdmin, isAuthenticated }: useAuthProps) => isAuthenticated && !isSuperAdmin,
                icon: ICONS.planing,
                children: AVAILABLE_REPORTS,
            },
            {
                title: 'Action Planning',
                path: PATH_ACTION_PLANING.home,
                icon: ICONS.planing,
            },
            {
                title: 'Audit',
                path: PATH_AUDIT_PLANING.home,
                icon: ICONS.audit,
                allowed: ({ isCompanyAdmin }: useAuthProps) => isCompanyAdmin
                /*allowed: ({ user }: useAuthProps) => {
                    return [
                        'company@admin.com',
                    ].includes(user?.email)
                },*/
            },
            /*{
                title: 'Prediction',
                path: PATH_PREDICTION.home,
                icon: ICONS.audit,
                //allowed: ({ isCompanyAdmin }: useAuthProps) => isCompanyAdmin
                allowed: ({ user }: useAuthProps) => {
                    return [
                        'company@admin.com',
                    ].includes(user?.email) && !isProduction()
                },
            },*/
            {
                title: 'Analytics+',
                path: PATH_ANALYTICS.home,
                icon: ICONS.analytics,
                allowed: ({ user }: useAuthProps) => user?.hasZohoAccess,
            },
            {
                title: 'Settings',
                path: PATH_SETTINGS.root,
                allowed: ({ isCompanyAdmin }: useAuthProps) => isCompanyAdmin,
                icon: ICONS.settings,
                children: [
                    {
                        title: 'Company Profile',
                        icon: ICONS.cpProfile,
                        path: PATH_SETTINGS.settings.profile,
                    },
                    {
                        title: 'Setup',
                        icon: ICONS.setup,
                        path: PATH_SETTINGS.settings.setup,
                    },
                    {
                        title: 'Company Hierarchy',
                        icon: ICONS.elements,
                        path: PATH_SETTINGS.settings.hierarchy,
                    },
                    {
                        title: 'Integration',
                        icon: ICONS.ic_integration,
                        path: PATH_SETTINGS.settings.integration,
                        allowed: ({ HasIntegrationsAccess }: useAuthProps & CompanySettingsProps) => HasIntegrationsAccess,
                    },
                    {
                        title: 'Users',
                        icon: ICONS.users,
                        path: PATH_SETTINGS.settings.users,
                    }
                ],
            },
            {
                title: 'Guide',
                path: PATH_SETTINGS.root,
                //allowed: ({ isCompanyAdmin }: useAuthProps) => isCompanyAdmin,
                icon: ICONS.ic_help,
                children: [
                    {
                        title: 'Overview',
                        icon: ICONS.ic_badget_help,
                        path: '#',
                    },
                    {
                        title: 'Create Initiatives',
                        icon: ICONS.ic_badget_help,
                        path: '#',
                    },
                    {
                        title: 'Settings',
                        icon: ICONS.ic_badget_help,
                        path: '#',
                    },
                ],
            },
            {
                title: 'Companies',
                path: PATH_SUPERADMIN.manage.companies,
                allowed: ({ isSuperAdmin }: useAuthProps) => isSuperAdmin,
                icon: ICONS.banking,
            },
            {
                title: 'Users',
                path: PATH_SUPERADMIN.manage.users,
                allowed: ({ isSuperAdmin }: useAuthProps) => isSuperAdmin,
                icon: ICONS.users,
            },
            {
                title: 'Notifications',
                path: PATH_SUPERADMIN.manage.notifications,
                allowed: ({ isSuperAdmin }: useAuthProps) => isSuperAdmin,
                icon: <NotificationsIcon />,
            },
            {
                title: 'Buglist',
                path: PATH_SUPERADMIN.manage.buglist,
                allowed: ({ isSuperAdmin }: useAuthProps) => isSuperAdmin,
                icon: ICONS.ic_mail,
            },
            {
                title: 'Log out',
                path: PATH_AUTH.logout,
                icon: ICONS.logout,
            },
        ],
    },
]

export default sidebarConfig.filter((i) => i)
