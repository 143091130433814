// @ts-nocheck

import * as d3 from 'd3'
import * as d3_7 from 'd3-7'
import {
    differenceInDays,
    differenceInMonths,
    formatISO,
    eachWeekOfInterval,
    endOfMonth,
    startOfMonth,
    endOfWeek,
    endOfDay,
    startOfDay,
    areIntervalsOverlapping,
    addYears,
} from 'date-fns'
import {find, forEach, get, groupBy, isArray, isEmpty, startsWith, uniq, isString} from 'lodash'
import typography from 'theme/typography'
import {toServerTime} from "utils/formatDate";
import './css/styles.css'

import './css/tooltip.css'
import {sortByCaseSensitive, sortByPriority} from "utils/string";
import { saveItemLocal } from '../../../utils/storage'

import { octagon } from './symbols/octagon'
import Countersink from './symbols/Countersink'
import { hexagon } from './symbols/hexagon'
import { triangleDown, triangleRight, triangleLeft } from './symbols/triangle'
import pentagon from './symbols/pentagon'
import { clover, drop, minus, pillow, vbar, cloud, pin, cresent, sheild } from './symbols/symbolsRaw'

/*

This code is based on following convention:

https://github.com/bumbeishvili/d3-coding-conventions/blob/84b538fa99e43647d0d4717247d7b650cb9049eb/README.md


*/


function getScenarioData() {
    try {
        return JSON.parse(window.localStorage.getItem('scenariosToSave') || '') || {}
    } catch (err) {
        return {}
    }
}

export const colorRange = ['#59C6DF', '#4CB1D8', '#3A95CD', '#4462EC', '#7B5DE9', '#EB78AC']


export const availableSymbols = [
    'triangle',
    'square',
    'symbolCircle',
    'symbolCross',
    'symbolDiamond',
    'symbolWye',
    'symbolAsterisk',
    'symbolDiamond2',
    'symbolPlus',
    'symbolSquare2',
    'symbolTriangle2',
    'symbolTimes',
    'Countersink',
    //'hexagon',
    //'octagon',
    'triangleDown',
    'triangleRight',
    'triangleLeft',
    'pentagon',
    'pillow',
    'drop',
    //'minus',
    //'vbar',
    'clover',
    'cloud',
    'pin',
    'cresent',
    'sheild'
]

const symbolMap = {
    'hexagon': hexagon,
    'octagon': octagon,
    'triangleDown': triangleDown,
    'triangleRight': triangleRight,
    'triangleLeft': triangleLeft,
    'pentagon': pentagon,
    'pillow': pillow,
    'Countersink': Countersink,
    'drop': drop,
    'minus': minus,
    'vbar': vbar,
    'clover': clover,
    'cloud': cloud,
    'pin': pin,
    'cresent': cresent,
    'sheild': sheild
}

export default function Chart(inAttr) {
    // Exposed variables
    let attrs = {
        id: 'ID' + Math.floor(Math.random() * 1000000), // Id for event handlings
        svgWidth: 400,
        svgHeight: 400,
        marginTop: 5,
        marginBottom: 5,
        marginRight: 5,
        marginLeft: 5,
        container: 'body',
        defaultFont: typography.fontFamily,
        firstLoad: true,
        allImpactDates: [],
        checkedOptions: {},
        token: window.localStorage.getItem('liveAccessToken'),
        filterDates: [],
        allImpactTypes: [],
        data: null,
        execSummaryData: [],
        emptyDayFill: '#E5E2E0',
        rect: { height: 25 },
        button: { height: 35 },
        heatmapsContainer: null,
        axisContainer: null,
        colorRange,
        editScenario: false,
        storeImpacts: true,
        scenariosToSave: getScenarioData(),
        SavedScenarios: [],
        setScenariosToSave: () => {},
        updateScenariosUrl: '',
        symbols: availableSymbols,
        activitySymbols: {},
        activitySymbolColors: {},
        allDivisionsData: null,
        allDivisionsDataUsed: null,
        impactRecommneds: [],
        graphFilters: {
            Scenarios: [],
            symbols: [],
            type: 'Strategy',
            button: 'Scenario',
            sortByTime: false,
        },
        ...inAttr,
    }

    //InnerFunctions which will update visuals
    var updateData

    //Main chart object
    const main = function () {
        if (isEmpty(attrs.data)) {
            return
        }
        attrs.renders = true
        attrs.editScenario = !isEmpty(attrs.graphFilters.Scenarios)
        //Drawing containers
        let container = d3.select(attrs.heatmapsContainer)
        let axisContainer = d3.select(attrs.axisContainer)

        container.selectAll('.strategy-header').remove()

        if (!attrs.data.Activity) attrs.data.Activity = []

        const symbolOptions = attrs.graphFilters.symbols ///attrs.checkedOptions.symbol;
        attrs.activitySymbols = {};
        attrs.activitySymbolColors = {};
        // heatmapBlockWidth

        const calMonthTicks = () => {
            const end = endOfMonth(attrs.filterDates[1] || new Date())
            const start = startOfMonth(attrs.filterDates[0] || new Date())
            let monthDiff = differenceInMonths(end, start) + 1;

            if (monthDiff < 0) {
                monthDiff = -monthDiff
            }
            if (monthDiff > 3) {
                return monthDiff
            }
            return eachWeekOfInterval({
                start: start,
                end: end
            }, { weekStartsOn: 1 }).length || 12
        }

        forEach(symbolOptions, (i) => {
            attrs.activitySymbols[i.name] = i.sign //attrs.symbols[Math.floor(Math.random() * (attrs.symbols.length - 1))]
            attrs.activitySymbolColors[i.name] = i.color //attrs.symbols[Math.floor(Math.random() * (attrs.symbols.length - 1))]
        })

        let heatmapsRange = getTimeDomain()

        attrs.dateFilterChanged = false

        let containerRect = container.node().getBoundingClientRect()
        if (containerRect.width > 0) attrs.svgWidth = containerRect.width;

        //Calculated properties
        var calc = {}
        calc.id = 'ID' + Math.floor(Math.random() * 1000000) // id for event handlings
        calc.chartLeftMargin = attrs.marginLeft
        calc.chartTopMargin = attrs.marginTop
        calc.chartWidth = attrs.svgWidth - attrs.marginRight - calc.chartLeftMargin - 50
        calc.chartHeight = attrs.svgHeight
        calc.center = { x: calc.chartWidth / 2, y: calc.chartHeight / 2 }

        ;(attrs.data.LevelTimeRange || []).forEach(function (d) {
            d.Avg = (d.Min + d.Max) / 2
        })

        let groupedByStrategy = getNestedData()
        ///attrs.execSummaryData = uniqBy(groupedByStrategy.sorted, 'InitiativeId');
        attrs.execSummaryData = groupedByStrategy.sorted

        const maxDaySize = d3.max(attrs.data.bubbles.map((x) => x.dayMaxSize))
        const maxWeekSize = maxDaySize * 7
        const maxImpactSize = d3.max(attrs.data.bubbles.map((x) => x.maxImpactSize))

        setCurrentScenarioImpacts()

        const colorScale = d3.scaleLinear().range(attrs.colorRange).domain(getColorDomain(maxWeekSize + 5))
        const impactColorScale = d3.scaleLinear().range(attrs.colorRange).domain(getColorDomain(maxImpactSize + 5))
        let dates = getDatesArray()

        let weeksArray = getSubArray(dates)

        let initiativeAreaWidth = calc.chartWidth / 4

        let companyStrategySize = 30

        let heatmapBlockWidth = calc.chartWidth - initiativeAreaWidth - companyStrategySize
        // heatmapBlockWidth = 550;

        const monthTicks = Math.min(calMonthTicks(), Math.ceil(heatmapBlockWidth / 40))
        calc.rectWidth = heatmapBlockWidth / weeksArray.length - 2

        let timeScale = d3.scaleTime().domain(heatmapsRange).range([0, heatmapBlockWidth])

        addMonthAxis()
       // addGradient()

        removeHeatmapElements()

        if ((attrs.execSummaryData||[]).length === 1) {
            addStrategyLine(
                attrs.lastByIndex[attrs.lastByIndex.length - 1],
                attrs.lastByIndex[attrs.lastByIndex.length - 1]
            )
        }

        container.style('padding-top', '10px')
        attrs.execSummaryData.forEach(function (_currentInitiative, index) {
            const currentInitiative = filterImpactsByColumnKey(attrs.graphFilters.type, _currentInitiative, attrs.keysByIndex[index])
            //if (attrs.centeredByIndex[index]) addCompanyStrategyName(currentInitiative, index)
            if (attrs.lastByIndex[index - 1] || index === 0) addCompanyStrategyName(currentInitiative, index)

            //Add svg
            const svg = container
                .append('svg')
                .classed('svg-heatmap-container', true)
                .attr('id', 'exec-summary-svg-' + (index + 1))
                .style('width', calc.chartWidth.toFixed(0))
                .attr('height', calc.chartHeight)
                .attr('font-family', attrs.defaultFont)
                .style('overflow', 'visible')
                .style('transform', function (d, i) {
                    let x = companyStrategySize + 'px'
                    let y = 5 + 'px'
                    return 'translate(' + x + ',' + y + ')'
                })
                .attr('data-strategy', currentInitiative.Strategy[0]||'')
            if (index === 0) {
                svg.style('margin-top', '15px')
            }
            //Add container g element
            const chart = svg.patternify({ tag: 'g', selector: 'chart' })

            //let isHighLight = (attrs.data.impactRecommneds||[]).some(x => x.InitiativeId === currentInitiative.InitiativeId);
            //if(isHighLight){
            ///svg.style('border', '2px solid rgba(255, 216, 0, 0.75)')
            //        .style('box-sizing', 'content-box');
            //}
            addHeader(chart, currentInitiative)
            addHeatmapRects(chart, currentInitiative)
            chart.datum({
                render: () => {
                    addButtons(chart, currentInitiative)
                    addCenterText(chart)
                    addImpactGrid(chart, currentInitiative)
                },
                showAll: () => {
                    chart.selectAll('.heatmap-rects').attr('data-clicked', null)
                    chart
                        .selectAll('.heatmap-rects')
                        .attr('width', calc.rectWidth)
                        .attr('height', attrs.rect.height)
                        .attr('x', (d) => d.data_x)
                        .attr('y', (d) => d.data_y)

                    unhighlightImpactRange(chart)
                    shortImpactNames(chart)
                }
            })
            //addButtons(chart, currentInitiative);
            /// --- REMOVE AT ALL addCenterText(chart);
            //addImpactGrid(chart, currentInitiative);
            if (attrs.lastByIndex[index]) {
                addStrategyLine(attrs.lastByIndex[index], attrs.keysByIndex[index + 1])
            }
        })

        addStrategyLine(
            attrs.lastByIndex[attrs.lastByIndex.length - 1],
            attrs.lastByIndex[attrs.lastByIndex.length - 1]
        )
        fixFirstStrategyPosition()
        visuallyHighlightRanges()
        attrs.firstLoad = false

        // ################################# CUSTOM FUNCTIONS ##############################

        function visuallyHighlightRanges() {
            if (!attrs.data.highlightDates) {
                return
            }

            let [min, max] = getTimeDomain()

            ///console.log(' attrs.data.highlightDates',  attrs.data.highlightDates)
            const allDates = Array.from(
                new Set(
                    attrs.data.highlightDates
                        .map((i) =>
                            i.dates.filter(function (d) {
                                return d.getTime() >= min.getTime() && d.getTime() <= max.getTime()
                            })
                        )
                        .flat()
                )
            )

            d3.selectAll('.highlight-period-overlay').remove()

            generateHighlightRanges(allDates).forEach(function (range) {
                addHighlightPeriodBlur(range)
            })
        }

        function addHighlightPeriodBlur(range) {
            let heatmapSvg = d3.select('.svg-heatmap-container')
            if (heatmapSvg.empty()) return

            const heatmapsContainer = d3.select('#heatmaps-container')
            const height = heatmapsContainer.style('height')

            const start = timeScale(range[0])
            const end = timeScale(range[range.length - 1])
            const oneDayWidth = timeScale.range()[1] / 365
            const width = end - start + oneDayWidth

            const highlightedArea = heatmapsContainer
                .insert('div', ':first-child')
                .classed('highlight-period-overlay', true)
                .style('width', width + 'px')
                .style('height', height)
                .style('background-color', 'black')
                .style('position', 'absolute')
                .style('left', '0px')
                //.style('top', heatmapsContainer.node().offsetTop+'px')
                .style('opacity', '0.2')
                //.style('z-index', 1000)


            const heatmapClientPosition = d3.select('.heatmap-group').node().getBoundingClientRect()
            const highlightedAreaPosition = highlightedArea.node().getBoundingClientRect()
            const areaLeft = heatmapClientPosition.left - highlightedAreaPosition.left

            highlightedArea.style('left', areaLeft + start + 'px')
        }

        function generateHighlightRanges(allDates) {
            if (allDates.length === 1) return [allDates]
            try {
                allDates = allDates.filter((date, i, self) =>
                    self.findIndex(d => d.getTime() === date.getTime()) === i
                )
            } catch (e) {
                console.log(e)
            }

            let sortedDates = allDates.sort((a, b) => a.getTime() - b.getTime())

            let ranges = []
            let latestRange = sortedDates.slice(0, 1)

            for (let i = 1; i < sortedDates.length; i++) {
                let currentDate = sortedDates[i]
                let latestRangeLastDate = latestRange[latestRange.length - 1]

                if (datediff(latestRangeLastDate, currentDate) === 1) {
                    latestRange.push(currentDate)
                } else {
                    ranges.push(latestRange)
                    latestRange = [currentDate]
                }

                if (i === sortedDates.length - 1) ranges.push(latestRange)
            }

            return ranges
        }

        function removeHeatmapElements() {
            container.selectAll('.strategy-header, .svg-heatmap-container, .strategy-line-container').remove()
        }

        function fixFirstStrategyPosition() {
            let firstStrategy = d3.select('.strategy-header').select('p')
            if (firstStrategy.empty()) return
            let top = parseFloat(firstStrategy.style('top'))
            firstStrategy.style('top', top + 10 + 'px')
        }

        function addImpactGrid(chart, currentInitiative, show) {
            const gridBlockHeight = 30
            const impactsCount = currentInitiative.Impacts.length /// attrs.data.allImpacts[currentInitiative.InitiativeId].length ///currentInitiative.Impacts.length;

            let yScale = d3
                .scaleLinear()
                .domain([0, impactsCount - 1])
                .range([0, impactsCount * gridBlockHeight])
            let xAxis = d3
                .axisBottom(timeScale)
                .ticks(monthTicks)
                .tickSize((impactsCount + 1) * gridBlockHeight)
            let yAxis = d3
                .axisLeft(yScale)
                .ticks(impactsCount - 1)
                .tickSize(-heatmapBlockWidth)

            chart.selectAll('.impact-grid-container').remove()

            let gridContainer = chart
                .patternify({ tag: 'g', selector: 'impact-grid-container' })
                .attr('transform', function (d) {
                    return 'translate(' + initiativeAreaWidth + ',' + 90 + ')'
                })
                .attr('display', show ? null : 'none')

            const xAxisGroup = gridContainer.patternify({ tag: 'g', selector: 'x-axis-group' }).call(xAxis)

            const yAxisGroup = gridContainer.patternify({ tag: 'g', selector: 'y-axis-group' }).call(yAxis)

            removeAxisElements(chart, xAxisGroup, yAxisGroup)

            const oneYearRange = getTimeDomain()
            const height = 15

            const impactRectsContainer = gridContainer.patternify({ tag: 'g', selector: 'impact-rects-container' })

            const filteredImpacts = validImpacts(currentInitiative, oneYearRange)

            let recommendImpacts = {}

            impactRectsContainer
                .patternify({ tag: 'rect', selector: 'impact-rects', data: filteredImpacts })
                .attr('x', function (d) {
                    let impactFromDate = new Date(d.ImpactFromDate)
                    let from = impactFromDate > oneYearRange[0] ? impactFromDate : oneYearRange[0]
                    d.impactRectX = timeScale(from)
                    return d.impactRectX
                })
                .attr('height', height)
                .attr('y', function (d, i) {
                    ///console.log(impactRectsContainer, d,i)
                    let y = gridBlockHeight / 2 + yScale(i)
                    if (d['recommendedFirstDate'] === true) {
                        recommendImpacts[d.InitiativeId] = y
                    }
                    if (d['recommendedFirstDate'] === false) {
                        d.impactRectY = recommendImpacts[d.InitiativeId] || y
                    } else {
                        d.impactRectY = y
                    }

                    return d.impactRectY
                })
                .attr('width', function (d) {
                    let impactFromDate = new Date(d.ImpactFromDate)
                    let from = impactFromDate > oneYearRange[0] ? impactFromDate : oneYearRange[0]
                    let impactToDate = new Date(d.ImpactToDate)
                    let to = impactToDate < oneYearRange[1] ? impactToDate : oneYearRange[1]
                    let width = timeScale(to) - timeScale(from)
                    d.impactRectWidth = width
                    return width
                })
                .attr('fill', function (d) {
                    return impactColorScale(d.totalSize)
                })
                .attr('opacity', 0.8)
                ///.style("stroke-dasharray", ("3, 3"))
                .attr('rx', 5)
                .attr('ry', 5)

            impactRectsContainer
                .patternify({ tag: 'text', selector: 'impact-texts', data: filteredImpacts })
                .text(function (d) {
                    let text = d.ImpactName
                    if (goLiveLite(d).showStar) text = addMonthNumber(d, text)
                    d.customImpactName = text
                    return text
                })
                .attr('font-family', typography.fontFamily)
                .style('font-size', '11px')
                .attr('fill', function (d) {
                    let diff = maxImpactSize - d.totalSize
                    return diff < 300 ? 'white' : 'black'
                })
                .attr('data-text', function (d) {
                    return sliceImpactText(this, this.getComputedTextLength(), 10, d.impactRectWidth - 10, 0)
                    ///return d3.select(this).text();
                })
                .attr('x', function (d) {
                    let backgroundX = d.impactRectX
                    let textWidth = this.getComputedTextLength()
                    let x = backgroundX + (d.impactRectWidth - textWidth) / 2
                    if (goLiveLite(d).showStar) x += 18
                    return x
                })
                .attr('y', function (d, i) {
                    let backgroundY = d.impactRectY
                    let y = backgroundY + 10 //custom align vertically
                    return y
                })
                .on('mouseenter', function (d, i) {
                    if (
                        d3
                            .selectAll('.heatmap-rects')
                            .filter(function (d) {
                                return d3.select(this).attr('data-clicked')
                            })
                            .size() > 0
                    )
                        return
                    impactTextHover(chart, this, d, i)
                })
                .on('mouseleave', function (d, i) {
                    if (
                        d3
                            .selectAll('.heatmap-rects')
                            .filter(function (d) {
                                return d3.select(this).attr('data-clicked')
                            })
                            .size() > 0
                    )
                        return
                    impactTextUnhover(chart, this, d, i)
                })
                .attr('rx', 5)
                .attr('ry', 5)
        }

        function addMonthNumber(d, text) {
            let day = new Date(d.ImpactFromDate).getDate()
            let monthShort = new Date(d.ImpactFromDate).toLocaleString('default', { month: 'short' })

            return text + ' ' + day + ' ' + monthShort
        }

        function goLiveLite(d) {
            return {
                showStar: getCurrentActivities().some((activity) => d.Activities.includes(activity)),
                shape: 'star',
            }
        }

        function getCurrentActivities() {
            let defaultActivity = ['Go Live']
            if (isEmpty(attrs.activitySymbols)) return defaultActivity
            return Object.keys(attrs.activitySymbols) ///.slice(0,3)
        }

        function impactTextHover(chart, textNode, d, index) {
            let textOldWidth = textNode.getComputedTextLength()
            d3.select(textNode).text(d.ImpactName)
            let textNewWidth = textNode.getComputedTextLength()
            let widthChange = textNewWidth - textOldWidth
            if (
                chart
                    .selectAll('.heatmap-rects')
                    .filter(function (x) {
                        return d3.select(this).attr('data-clicked')
                    })
                    .size() == 0
            )
                chart
                    .selectAll('.impact-rects')
                    .filter((x, i) => i == index)
                    .transition()
                    .duration(100)
                    .attr('width', (x) => x.impactRectWidth + widthChange)
        }

        function impactTextUnhover(chart, textNode, d, index) {
            d3.select(textNode).text(d3.select(textNode).attr('data-text'))
            if (
                chart
                    .selectAll('.heatmap-rects')
                    .filter(function (x) {
                        return d3.select(this).attr('data-clicked')
                    })
                    .size() === 0
            )
                chart
                    .selectAll('.impact-rects')
                    .transition()
                    .duration(100)
                    .attr('width', (x) => x.impactRectWidth)
        }

        function sliceImpactText(textNode, textWidth, margin, rectWidth, iterationCount) {
            const text = d3.select(textNode)
            if (rectWidth > textWidth + margin) {
                return text.text()
            }
            while (text.node().getComputedTextLength() > rectWidth) {
                text.text(text.text().slice(0, -4) + '...')
                if (text.text().length <= 6) break
            }
            try {
                text.data()[0].reduced = true
            } catch (e) {
                ///
            }

            return text.text()
        }

        function validImpacts(currentInitiative, oneYearRange) {
            return currentInitiative.Impacts.filter(function (d) {
                return validImpactDate(d, oneYearRange)
            })
        }

        function validImpactDate(d, oneYearRange) {
            let impactFromDate = new Date(d.ImpactFromDate)
            let from = impactFromDate > oneYearRange[0] ? impactFromDate : oneYearRange[0]
            let impactToDate = new Date(d.ImpactToDate)
            let to = impactToDate < oneYearRange[1] ? impactToDate : oneYearRange[1]
            return to >= from
        }

        function removeAxisElements(chart, xAxisGroup, yAxisGroup) {
            xAxisGroup.selectAll('.domain').remove()
            xAxisGroup.selectAll('text').remove()
            xAxisGroup.select('.tick').remove()

            yAxisGroup.selectAll('.domain').remove()
            yAxisGroup.selectAll('text').remove()
            yAxisGroup.select('.tick').remove()

            chart
                .selectAll('path')
                .filter(function (d) {
                    return !d3.select(this).classed('impact-stars')
                })
                .attr('stroke', 'gray')
                .attr('stroke-width', 0.3)
            chart.selectAll('line').attr('stroke', 'gray').attr('stroke-width', 0.3)
        }

        function addCenterText(chart) {
            chart
                .patternify({ tag: 'g', selector: 'center-text-group' })
                .patternify({ tag: 'text', selector: 'center-text' })
                .text('')
                .attr('font-family', attrs.defaultFont)
                .style('font-size', '11px')
        }

        function addButtons(chart, currentInitiative) {
            addInfoButton(chart, currentInitiative, addCloseButton(chart, currentInitiative))
            addPreviousButton(chart, currentInitiative)
            addShowButton(chart, currentInitiative, addNextButton(chart, currentInitiative))
        }

        function addShowButton(chart, currentInitiative, nextButtonGroup) {
            let showButtonGroup = chart
                .patternify({ tag: 'g', selector: 'show-button-group' })
                .classed('icon-buttons', true)
                .attr('data-clicked', null)
                .on('click', function (d) {
                    chart.selectAll('.heatmap-rects').attr('data-clicked', null)
                    chart
                        .selectAll('.heatmap-rects')
                        .attr('width', calc.rectWidth)
                        .attr('height', attrs.rect.height)
                        .attr('x', (d) => d.data_x)
                        .attr('y', (d) => d.data_y)

                    unhighlightImpactRange(chart)
                    shortImpactNames(chart)
                    visuallyHighlightRanges()
                })
                .attr('display', 'none')

            callButtonTemplate(showButtonGroup, 'Show all', 'show', 'all', 'end')

            showButtonGroup.attr('transform', function (d) {
                let transform = nextButtonGroup.attr('transform')
                let translate = transform.substring(transform.indexOf('(') + 1, transform.indexOf(')')).split(',')
                let closeX = parseFloat(translate[0])
                let infoWidth = showButtonGroup.node().getBoundingClientRect().width
                let distance = 12
                let x = closeX - distance - infoWidth
                let y = calc.chartHeight + 12
                return 'translate(' + x + ',' + y + ')'
            })

            return showButtonGroup
        }

        function addNextButton(chart, currentInitiative) {
            let nextButtonGroup = chart
                .patternify({ tag: 'g', selector: 'next-button-group' })
                .classed('icon-buttons', true)
                .on('click', function (d) {
                    let clickedRect = chart.selectAll('.heatmap-rects').filter(function (d) {
                        return d3.select(this).attr('data-clicked')
                    })

                    if (clickedRect.empty()) {
                        let firstRect = getFirstRect(chart)
                        rectClickAction(chart, firstRect.node(), firstRect.data()[0])
                        return
                    }

                    let nextRect = chart.selectAll('.heatmap-rects').filter(function (d) {
                        if (parseInt(d3.select(this).attr('data-index')) == parseInt(clickedRect.attr('data-index')) + 1) return true
                        return false
                    })

                    if (nextRect.empty()) return

                    rectClickAction(chart, nextRect.node(), nextRect.data()[0])
                    hideMoreDetailBox()
                })
                .attr('display', 'none')

            callButtonTemplate(nextButtonGroup, 'Next', 'next', '\\276D', 'end')

            nextButtonGroup.attr('transform', function (d) {
                let x = calc.chartWidth - nextButtonGroup.node().getBoundingClientRect().width + 7 - companyStrategySize
                let y = calc.chartHeight + 12
                return 'translate(' + x + ',' + y + ')'
            })

            return nextButtonGroup
        }

        function addPreviousButton(chart, currentInitiative) {
            let previousButtonGroup = chart
                .patternify({ tag: 'g', selector: 'previous-button-group' })
                .classed('icon-buttons', true)
                .attr('transform', function (d) {
                    let x = initiativeAreaWidth
                    let y = calc.chartHeight + 12
                    return 'translate(' + x + ',' + y + ')'
                })
                .on('click', function (d) {
                    let clickedRect = chart.selectAll('.heatmap-rects').filter(function (d) {
                        return d3.select(this).attr('data-clicked')
                    })

                    if (clickedRect.empty()) {
                        let lastRect = getLastRect(chart)
                        rectClickAction(chart, lastRect.node(), lastRect.data()[0])
                        return
                    }

                    let previousRect = chart.selectAll('.heatmap-rects').filter(function (d) {
                        if (parseInt(d3.select(this).attr('data-index')) == parseInt(clickedRect.attr('data-index')) - 1) return true
                        return false
                    })

                    if (previousRect.empty()) return

                    rectClickAction(chart, previousRect.node(), previousRect.data()[0])
                    hideMoreDetailBox()
                })
                .attr('display', 'none')

            callButtonTemplate(previousButtonGroup, 'Previous', 'previous', '\\276C', 'start')

            return previousButtonGroup
        }

        function getLastRect(chart) {
            let index = 0
            chart.selectAll('.heatmap-rects').each(function (d) {
                let data_index = parseInt(d3.select(this).attr('data-index'))
                if (data_index > index) index = data_index
            })

            let lastRect = chart.selectAll('.heatmap-rects').filter(function (d) {
                return d3.select(this).attr('data-index') == index.toString()
            })

            return lastRect
        }

        function getFirstRect(chart) {
            let index = 0

            let firstRect = chart.selectAll('.heatmap-rects').filter(function (d) {
                return d3.select(this).attr('data-index') == index.toString()
            })

            return firstRect
        }

        function addCloseButton(chart, currentInitiative) {
            let closeButtonGroup = chart
                .patternify({ tag: 'g', selector: 'close-button-group' })
                .classed('icon-buttons', true)
                .on('click', function (d) {
                    chart.select('.impact-grid-container').attr('display', 'none')
                    const clickedRect = chart.selectAll('.heatmap-rects').filter(function (d) {
                        return d3.select(this).attr('data-clicked')
                    })
                    decreaseRectSize(clickedRect.node())
                    if (clickedRect.data()[0]) clickedRect.attr('data-clicked', null)
                    chart.selectAll('.icon-buttons').attr('display', 'none')
                    chart.select('.center-text').text('')
                    d3.select(chart.node().parentNode).attr('height', calc.chartHeight)
                    visuallyHighlightRanges()
                })
                .attr('display', 'none')

            callButtonTemplate(closeButtonGroup, 'Close', 'close', '\\2613', 'end')

            closeButtonGroup.attr('transform', function (d) {
                let x = calc.chartWidth - closeButtonGroup.node().getBoundingClientRect().width - 156 - companyStrategySize
                let y = 38
                return 'translate(' + x + ',' + y + ')'
            })

            return closeButtonGroup
        }

        function addInfoButton(chart, currentInitiative, closeButtonGroup) {
            let infoButtonGroup = chart
                .patternify({ tag: 'g', selector: 'info-button-group' })
                .classed('icon-buttons', true)
                .on('click', function (d) {
                    if (currentInitiative) {
                        openInitiativePage(currentInitiative)
                    }
                })
                .attr('display', 'none')

            callButtonTemplate(infoButtonGroup, 'Info', 'info', '', 'end')

            infoButtonGroup.attr('transform', function (d) {
                let transform = closeButtonGroup.attr('transform')
                let translate = transform.substring(transform.indexOf('(') + 1, transform.indexOf(')')).split(',')
                let closeX = parseFloat(translate[0])
                let infoWidth = infoButtonGroup.node().getBoundingClientRect().width
                let distance = 12
                let x = closeX - distance - infoWidth
                let y = 38
                return 'translate(' + x + ',' + y + ')'
            })

            return infoButtonGroup
        }

        function openInitiativePage(currentInitiative) {
            window.open(
                `${window.location.origin}/initiatives/wizard/${currentInitiative.InitiativeId}`,
                '_blank' // <- This is what makes it open in a new window.
            )
        }

        function callButtonTemplate(group, text, className, iconCode, iconPosition) {
            const textDistance = 15
            iconCode = ''

            const buttonText = group
                .patternify({ tag: 'text', selector: className + '-button-text' })
                .attr('font-size', '14px')
                .attr('font-family', typography.fontFamily)
                .attr('fill', '#000000')
                .attr('cursor', 'pointer')

            const firstSpan = buttonText
                .append('tspan')
                .text(function (d) {
                    if (iconPosition === 'start') return iconCode
                    return text
                })
                .attr('dy', 1)
                .attr('font-size', iconPosition === 'start' ? 10 : 14)
                .attr('fill', '#000000')
                .attr('dx', 5)

            const secondSpan = buttonText
                .append('tspan')
                .text(function (d) {
                    if (iconPosition === 'start') return text
                    return iconCode
                })
                .attr('dy', iconPosition === 'start' ? 1 : 0)
                .attr('dx', 5)
                .attr('fill', '#000000')
                .attr('font-size', iconPosition === 'start' || iconCode === 'all' ? 14 : 10)

            const buttonRect = group
                .patternify({ tag: 'rect', selector: className + '-button-rect' })
                .attr('height', attrs.button.height)
                .attr('width', function (d) {
                    let firstSpanLength = firstSpan.node().getComputedTextLength()
                    let secondSpanLength = secondSpan.node().getComputedTextLength()
                    let rectWidth = firstSpanLength + secondSpanLength + textDistance * 2
                    return rectWidth
                })
                .attr('fill', 'white')
                .style('stroke-width', '0.8px')
                .style('stroke', '#ddd')
                .attr('rx', '2')
                .attr('ry', '2')
                .attr('cursor', 'pointer')
                .on('mouseenter', function (d) {
                    d3.select(this).transition().duration(200).style('fill', '#EFEFEF')
                })
                .on('mouseleave', function (d) {
                    d3.select(this).transition().duration(200).style('fill', 'white')
                })

            buttonText
                .on('mouseenter', function (d) {
                    buttonRect.transition().duration(300).style('fill', '#EFEFEF')
                })
                .on('mouseleave', function (d) {
                    buttonRect.transition().duration(300).style('fill', 'white')
                })

            bringToTop(buttonText.node())

            buttonText.attr('x', textDistance / 2).attr('y', function (d) {
                return attrs.button.height / 2 + this.getBoundingClientRect().height / 5
            })
        }

        function bringToTop(targetElement) {
            // put the element at the bottom of its parent
            targetElement.parentNode.appendChild(targetElement)
        }

        function addHeatmapRects(chart, currentInitiative) {
            chart.selectAll('.heatmap-group').remove()

            let heatmapGroup = chart.patternify({ tag: 'g', selector: 'heatmap-group' }).attr('transform', function (d) {
                let x = initiativeAreaWidth
                let y = 0 // heatmap rects vertical distance
                return 'translate(' + x + ',' + y + ')'
            })

            /// let liveImpacts = goLiveImpacts(currentInitiative, getTimeDomain());

            heatmapGroup.selectAll('.impact-stars').remove()
            /*
            //
             */
            const chosenActivities = getCurrentActivities()
            let weeks = 0
            const elem = heatmapGroup
                .patternify({ tag: 'rect', selector: 'heatmap-rects', data: weeksArray }).attr('width', calc.rectWidth)
                .attr('height', attrs.rect.height)
                .attr('x', function (d, i) {
                    d.data_x = timeScale(d[0])
                    d3.select(this).attr('data-index', i)
                    return d.data_x
                })
                .attr('y', function (d) {
                    d.data_y = 0
                    return d.data_y
                })
                .attr('fill', function (d) {
                    const weekSize = calculateWeekSize(currentInitiative, d)
                    const elem = d3.select(this)
                    elem.attr('data-week-size', weekSize)
                    if (weekSize === 0) {
                        return 'transparent' //attrs.emptyDayFill
                    }
                    //console.log('ddd', this.getAttribute("width"))
                    elem.attr('cursor', 'pointer')
                    weeks += 1
                    return colorScale(weekSize)
                })
                .attr('data-rating-star', function (d, i) {
                    const elem = d3.select(this)
                    if (elem.attr('data-week-size') === 0 ) {
                        return false
                    }
                    ///if (liveImpacts.length == 0) return false;
                    let heatmapBlockNode = this
                    /// let liveImpacts = goLiveImpacts(currentInitiative, getTimeDomain());
                    const oneYearRange = getTimeDomain()
                    currentInitiative.Impacts.filter(function (impact) {
                        const impactFromDate = new Date(impact.ImpactFromDate)
                        const from = impactFromDate > oneYearRange[0] ? impactFromDate : oneYearRange[0]
                        const impactToDate = new Date(impact.ImpactToDate)
                        const to = impactToDate < oneYearRange[1] ? impactToDate : oneYearRange[1]
                        return to >= from
                    }).forEach(function (impact) {
                        if ((impact['recommended'] || false) === true) {
                            if (
                                d.some((d) => {
                                    /// console.log('impact.ImpactDaySizes[f', impact.ImpactDaySizes[formatISO(d, {representation: 'date'})])
                                    return impact.ImpactDaySizes[formatISO(d, { representation: 'date' })] !== undefined
                                })
                            ) {
                                //heatmapBlockNode.style = `stroke-width:3;stroke:rgb(255, 216, 0, 0.75); stroke-dasharray: 0 ${w} 25 0;`
                                heatmapBlockNode.setAttribute('stroke-width', 3)
                                heatmapBlockNode.setAttribute('stroke', 'rgb(255, 216, 0, 0.75)')
                                const w = heatmapBlockNode.getAttribute('width')
                                const h = heatmapBlockNode.getAttribute('height')
                                heatmapBlockNode.setAttribute('stroke-dasharray', `${w} ${h} ${w}`)
                            }
                        }

                        const dateWeek = { start: startOfDay(d[0]) ,end: endOfDay(d[d.length - 1])}
                        let activityFromDate = impact.ActivityFromDate
                        if (oneYearRange[0].getTime() > activityFromDate.getTime()) {
                            activityFromDate = addDays(oneYearRange[0], 2)
                        }
                        chosenActivities.forEach(function (activity) {

                            if (impact.Activities.includes(activity)) {
                                const shape = attrs.activitySymbols[activity]
                                if (!shape) {
                                    return;
                                }
                                // if (d.map((x) => x.setHours(0, 0, 0, 0)).includes(goLiveDateNew)) {
                                if (areIntervalsOverlapping(dateWeek, { start: activityFromDate, end: activityFromDate}, { inclusive: true})) {
                                //if(datearr.some(r=> goLiveDateNew.includes(r))){
                                    //const shape = activity.includes('Go Live') ? 'star' : attrs.activitySymbols[activity] || 'star'
                                    const color = attrs.activitySymbolColors[activity] || 'yellow'
                                    addRatingStar(heatmapBlockNode, heatmapGroup, shape, color)
                                }
                            }
                        })
                    })
                    return false
                })
                .on('mouseenter', function (d) {
                    if (d3.select(this).attr('data-clicked')) return
                    rectHoverAction(chart, this)
                    const heatmapBlockNode = this
                    setTimeout(() => {
                        const arr = heatmapBlockNode.getAttribute('stroke-dasharray')
                        if (arr) {
                            const w = heatmapBlockNode.getAttribute('width')
                            const h = heatmapBlockNode.getAttribute('height')
                            heatmapBlockNode.setAttribute('stroke-dasharray', `${w} ${h} ${w}`)
                        }
                    }, 300)
                })
                .on('mouseleave', function (d) {
                    if (d3.select(this).attr('data-clicked')) return
                    rectUnhoverAction(chart, this)
                    const heatmapBlockNode = this
                    setTimeout(() => {
                        const arr = heatmapBlockNode.getAttribute('stroke-dasharray')
                        if (arr) {
                            const w = heatmapBlockNode.getAttribute('width')
                            const h = heatmapBlockNode.getAttribute('height')
                            heatmapBlockNode.setAttribute('stroke-dasharray', `${w} ${h} ${w}`)
                        }
                    }, 300)
                })
                .on('click', function (d, index) {
                    rectClickAction(chart, this, d)
                    visuallyHighlightRanges()
                })
                .attr('rx', 5)
                .attr('ry', 5)
                //.attr('cursor', 'pointer')

            /*if(currentInitiative.Impacts.some()){
                elem.style('border', '2px solid rgba(255, 216, 0, 0.75)')
                elem.style('box-sizing', 'content-box');
            }*/
            if (attrs.editScenario) {
                addDragableRect(chart, currentInitiative, heatmapGroup, weeks)
            }
        }

        function addDragableRect(chart, currentInitiative, heatmapGroup, weeks) {
            var from = getMinDateWithinArea(currentInitiative.Impacts)

            /// var to = getMaxDateWithinArea(currentInitiative.ImpactDateTos);

            var weekStartDay = getChosenDay(from, 'start')
            ///var weekEndDay = getChosenDay(to, 'end');

            var oneDayDistance = calc.rectWidth / 7

            var minDragLevel = d3.min(
                heatmapGroup
                    .selectAll('.heatmap-rects')
                    .data()
                    .map((d) => d.data_x)
            )
            var maxDragLevel =
                d3.max(
                    heatmapGroup
                        .selectAll('.heatmap-rects')
                        .data()
                        .map((d) => d.data_x)
                ) + calc.rectWidth

            var data = [{ x: timeScale(weekStartDay), width: weeks * calc.rectWidth + weeks / 4 }] // [{x: timeScale(weekStartDay), width: weeks * calc.rectWidth + oneDayDistance}]///timeScale(weekEndDay) - timeScale(weekStartDay)}];
            ///let oneYearRange = getTimeDomain();
            let draggableRect = heatmapGroup
                .patternify({ tag: 'rect', selector: 'draggable-rect', data: data })
                .attr('width', (d) => d.width)
                .attr('height', attrs.rect.height + 2)
                .attr('x', (d) => d.x)
                .attr('y', -1)
                .attr('fill', 'transparent')
                .attr('stroke', attrs.colorRange[2])
                .attr('stroke-width', 2)
                .attr('cursor', 'pointer')
                .call(
                    d3
                        .drag()
                        .on('start', function (d) {
                            d.startingX = parseFloat(d.x)
                        })
                        .on('drag', function (d) {
                            if (d3.event.x < minDragLevel || d3.event.x + d.width > maxDragLevel) return
                            d3.select(this)
                                .raise()
                                .attr('x', (d.x = d3.event.x))
                        })
                        .on('end', function (d) {
                            var daysDiff = parseInt((d3.event.x - d.startingX) / oneDayDistance)
                            if (daysDiff === 0) {
                                return
                            }
                            changeImpactDates(currentInitiative, daysDiff)
                            saveScenariosData(currentInitiative)
                            addHeatmapRects(chart, currentInitiative)
                            addImpactGrid(chart, currentInitiative, defaultShowGrid(chart))
                        })
                )
        }

        function saveScenariosData(currentInitiative) {
            //min date of show impacts within 1 year range
            var dateStart = d3.min(currentInitiative.ImpactDateFroms.map((d) => new Date(d)))
            const dateEnd = d3.max(currentInitiative.ImpactDateTos.map((d) => new Date(d)))
            if (!dateStart) dateStart = dateEnd

            var dateStartString = toServerTime(dateStart) ///.toISOString().slice(0, 19)

            attrs.scenariosToSave[currentInitiative.InitiativeId] = {
                DateStart: dateStartString,
                Expired: toServerTime(dateEnd), ///.toISOString().slice(0, 19),
                InitiativeId: currentInitiative.InitiativeId,
            }
            saveItemLocal('scenariosToSave', attrs.scenariosToSave)
        }

        function defaultShowGrid(chart) {
            var gridContainer = chart.select('.impact-grid-container')

            if (!gridContainer.empty() && gridContainer.attr('display') != 'none') return true

            return false
        }

        function changeImpactDates(currentInitiative, daysDiff, change = true) {
            const ImpactDateFroms = new Set()
            const ImpactDateTos = new Set()

            currentInitiative.Impacts = currentInitiative.Impacts.map(function (impact) {
                const ImpactFromDate = isArray(daysDiff) ? daysDiff[0] : addDays(new Date(impact.ImpactFromDate), daysDiff).toISOString().slice(0, 19)
                ImpactDateFroms.add(ImpactFromDate)
                const ImpactToDate = isArray(daysDiff) ? daysDiff[1] : addDays(new Date(impact.ImpactToDate), daysDiff).toISOString().slice(0, 19)
                ImpactDateTos.add(ImpactToDate)
                const res = {
                    ...impact,
                    ImpactFromDate: ImpactFromDate,
                    ImpactToDate: ImpactToDate,
                }
                const [daySizes, total] = getImpactDaySizes(res)
                return {
                    ...res,
                    ImpactDaySizes: daySizes,
                    totalSize: total,
                }
            })

            currentInitiative.impactCalculatedSize = d3.sum(currentInitiative.Impacts.map((d) => getCalculatedImpactSize(d)))

            currentInitiative.ImpactDateFroms = Array.from(ImpactDateFroms)
            currentInitiative.ImpactDateTos = Array.from(ImpactDateTos)

            currentInitiative.maxImpactDate = d3.max(currentInitiative.ImpactDateTos.map((x) => x.ImpactToDate))
            currentInitiative.maxImpactSize = d3.max(currentInitiative.Impacts.map((x) => x.totalSize))

            const [InitiativeDaySizes, dayMaxSize] = getInitiativeDaySizes(currentInitiative)

            currentInitiative.InitiativeDaySizes = InitiativeDaySizes
            currentInitiative.dayMaxSize = dayMaxSize

            if (change) {
                const index = attrs.execSummaryData.findIndex((i) => i.InitiativeId === currentInitiative.InitiativeId)
                if (index != -1) {
                    attrs.execSummaryData[index] = currentInitiative
                }
            }
        }

        function addDays(date, days) {
            var result = new Date(date)
            result.setDate(result.getDate() + days)
            return result
        }

        function getChosenDay(date, position) {
            var chosenWeek
            var chosenDay
            var withoutTime = new Date(date)
            withoutTime.setHours(0, 0, 0, 0)

            weeksArray.forEach(function (weekDays) {
                var weekStart = weekDays[0].getTime()
                var weekEnd = weekDays[weekDays.length - 1].getTime()

                if (weekStart <= withoutTime.getTime() && weekEnd >= withoutTime.getTime()) {
                    chosenWeek = weekDays
                }
            })

            if (!chosenWeek) chosenWeek = position === 'start' ? weeksArray[0] : weeksArray[weeksArray.length - 1]

            chosenDay = position === 'start' ? chosenWeek[0] : chosenWeek[chosenWeek.length - 1]

            return chosenDay
        }

        function getMinDateWithinArea(impacts) {
            var firstWeekDate = weeksArray[0][0]

            var startDates = impacts.map((d) => new Date(d.ImpactFromDate))

            var min = d3.min(startDates.filter((date) => date.getTime() >= firstWeekDate.getTime()))

            if (!min) min = firstWeekDate

            var impactPieceInRange = impacts.filter(
                (d) => new Date(d.ImpactFromDate).getTime() < min.getTime() && new Date(d.ImpactToDate).getTime() > firstWeekDate.getTime()
            )

            if (impactPieceInRange.length > 0) min = firstWeekDate

            return min
        }

        function getMaxDateWithinArea(impactDateTos) {
            var lastWeek = weeksArray[weeksArray.length - 1]
            var lastDay = lastWeek[lastWeek.length - 1]
            var max = d3.max(impactDateTos.map((d) => new Date(d)).filter((d) => d.getTime() < lastDay.getTime()))
            return max
        }

        function addRatingStar(blockNode, heatmapGroup, shape, color) {

            const impactShape = heatmapGroup
                .append('path')
                .classed('impact-stars', true)
                .attr('fill', color||'yellow')
                .attr('stroke', '#2f3030' ) // || 'black')
                .attr('stroke-width', '1px')
                .on('mouseenter', function (d, i) {
                    blockNode.dispatchEvent(new Event('mouseenter'));
                })
                .on('mouseleave', function (d, i) {
                    blockNode.dispatchEvent(new Event('mouseleave'));
                }).on('click', function (d, i) {
                    blockNode.dispatchEvent(new Event('click'));
                }).attr('cursor', 'pointer')

            if (shape === 'star') starTemplate(impactShape, blockNode)
            if (shape === 'triangle') triangleTemplate(impactShape, blockNode)
            if (shape === 'square') squareTemplate(impactShape, blockNode)

            if (symbolMap[shape]) {
                const shapeObj = symbolMap[shape]
                impactShape
                    .attr('d', function (d) { return shapeObj})
                    .attr('transform', function (d) {
                        const block = d3.select(blockNode)
                        const blockWidth = parseFloat(block.attr('width'))
                        let x2 = blockWidth/(shape === 'sheild' ? 6 : 2)
                        if (shape === 'sheild' && blockWidth < 15) {
                            if (blockWidth < 11) {
                                x2 = -2
                            } else {
                                x2 = 0
                            }
                        }
                        let x = parseFloat(block.attr('x')) +  x2
                        let y = shape === 'sheild' ? 4 : 13
                        return 'translate(' + x + ',' + y + ')'
                    })
            }

            if (startsWith(shape, 'symbol')) {
                /// if (shape === 'symbolTriangleLeft')
                const symbolGenerator = d3.symbol().size(150).type(d3_7[shape])

                heatmapGroup
                    .append('path')
                    .attr('d', symbolGenerator)
                    .classed('impact-stars', true)
                    .attr('fill', color||'yellow')
                    .attr('stroke', '#2f3030')
                    .attr('stroke-width', '1px')
                    .attr('transform', function (d) {
                        let x = parseFloat(d3.select(blockNode).attr('x')) + (parseFloat(d3.select(blockNode).attr('width'))/2)
                        let y = 13
                        let rotateStr = ''
                        /*if (!isEmpty(rotate)) {
                            rotateStr = ' rotate('+rotate+');'
                        }*/
                        // .attr("transform", function(d) { return "translate(" + 100 + "," + 100 + ") rotate(-45)"; })
                        return 'translate(' + x + ',' + y + ') ' + rotateStr
                    }).on('mouseenter', function (d, i) {
                        blockNode.dispatchEvent(new Event('mouseenter'));
                    })
                    .on('mouseleave', function (d, i) {
                        blockNode.dispatchEvent(new Event('mouseleave'));
                    }).on('click', function (d, i) {
                        blockNode.dispatchEvent(new Event('click'));
                    }).attr('cursor', 'pointer')
            }

            // 'symbolSquare', 'symbolCircle', 'symbolCross', 'symbolDiamond', 'symbolSquare'
        }

        function starTemplate(path, blockNode) {
            path
                .attr('stroke-width', '2px')
                .attr('d', function (d) {
                let starString =
                    'M 0.000 20.000L 23.511 32.361L 19.021 6.180L 38.042 -12.361L 11.756 -16.180L 0.000 -40.000L -11.756 -16.180L -38.042 -12.361L -19.021 6.180L -23.511 32.361L 0.000 20.000'
                return starString
            }).attr('transform', function (d) {
                let x = parseFloat(d3.select(blockNode).attr('x')) + (parseFloat(d3.select(blockNode).attr('width'))/2)
                let y = 13
                return 'translate(' + x + ',' + y + ') scale(0.2)'
            })
        }

        function triangleTemplate(path, blockNode) {
            path.attr('d', function () {
                let triangleString = 'M 10,150 L 70,10 L 130,150 z'
                return triangleString
            }).attr('transform', function () {
                let x = parseFloat(d3.select(blockNode).attr('x')) + (parseFloat(d3.select(blockNode).attr('width'))/6)
                let y = 4
                return 'translate(' + x + ',' + y + ') scale(0.1)'
            })
        }

        function squareTemplate(path, blockNode) {
            path.attr('d', function () {
                let squareString = 'M 10 10 H 90 V 90 H 10 L 10 10z'
                return squareString
            }).attr('transform', function () {
                let x = parseFloat(d3.select(blockNode).attr('x')) + (parseFloat(d3.select(blockNode).attr('width'))/2)
                let y = 5
                return 'translate(' + x + ',' + y + ') scale(0.15)'
            })
        }

        function calculateWeekSize(currentInitiative, d) {
            let sizes = []
            d.forEach(function (date) {
                sizes.push(currentInitiative.InitiativeDaySizes[formatDate(date)])
            })
            return d3.sum(sizes.filter((x) => x))
        }

        function addHeader(chart, currentInitiative) {
            //heatmap header group element
            let headerGroup = chart.patternify({ tag: 'g', selector: 'header-group' })
                .attr('transform', function (d) {
                    const x = 0
                    const y = 15
                    return 'translate(' + x + ',' + y + ')'
                })

            //heatmap header text element
            let header = headerGroup
                .patternify({ tag: 'text', selector: 'header' })
                .attr('font-size', '13.5px')
                .attr('font-family', attrs.defaultFont)
                .attr('fill', '#525252')
                .attr('dy', 0)
                .attr('x', 0)
                .attr('y', 0)
                .text(currentInitiative.InitiativeName)
                .text(function (d) {
                    return  sliceImpactText(this, this.getComputedTextLength(), 10, initiativeAreaWidth - 30, 0)
                })
        }

        function getLimit() {
            let limit = 205
            if (window.innerWidth > 1440) limit += (window.innerWidth - 1440) / 6
            return limit
        }

        function setOptimalText(textNode, limit) {
            let textElement = d3.select(textNode)
            let text = textElement.text()

            while (textNode.getComputedTextLength() > limit) {
                let words = text.split(' ')
                text = words.filter((d, i) => i <= words.length - 2).join(' ')
                textElement.text(text)
                textNode = textElement.node()
            }

            textElement.text(textElement.text() + '...')
        }

        function rectHoverAction(chart, node) {
            increaseRectSize(node)
            showRatingStar(chart)

            if (impactGridExpanded(chart)) {
                hideMoreDetailBox()
                changeCenterTextValue(chart, getCenterTextValue(node))
                highlightImpactRange(chart, node)
                if (
                    chart
                        .selectAll('.heatmap-rects')
                        .filter(function (d) {
                            return d3.select(this).attr('data-clicked')
                        })
                        .size() == 0
                )
                    fullImpactNames(chart)
            } else {
                displayMoreDetailBox(chart)
            }
        }

        function impactGridExpanded(chart) {
            const grid = chart.select('.impact-grid-container')
            return grid.size() ? (grid.attr('display') == 'none' ? false : true) : false
        }

        function highlightImpactRange(chart, node) {
            let customRange = getCustomRange(node)
            let oneYearRange = getTimeDomain()

            customRange.start = new Date(customRange.start)
            customRange.end = new Date(customRange.end)

            chart
                .selectAll('.impact-rects')
                .attr('x', function (d) {
                    let impactFromDate = new Date(d.ImpactFromDate)
                    let from = impactFromDate > oneYearRange[0] ? impactFromDate : oneYearRange[0]
                    let impactToDate = new Date(d.ImpactToDate)
                    let to = impactToDate < oneYearRange[1] ? impactToDate : oneYearRange[1]

                    let currentElement = d3.select(this).attr('display', null)
                    if (from > customRange.end || to < customRange.start) currentElement.attr('display', 'none')

                    let x = timeScale(from > customRange.start ? from : customRange.start)
                    d.highlightedImpactRectX = x
                    return x
                })
                .attr('width', function (d) {
                    let impactToDate = new Date(d.ImpactToDate)
                    let to = impactToDate < oneYearRange[1] ? impactToDate : oneYearRange[1]

                    let width = timeScale(to < customRange.end ? to : customRange.end) - d.highlightedImpactRectX

                    if (width < 0) width = 0
                    d.highlightedImpactRectWidth = width
                    return width
                })

            chart.selectAll('.impact-texts').attr('fill', 'black')
        }

        function unhighlightImpactRange(chart) {
            let clickedHeatmapRects = chart.selectAll('.heatmap-rects').filter(function (d) {
                return d3.select(this).attr('data-clicked')
            })

            if (clickedHeatmapRects.size() > 0) highlightImpactRange(chart, clickedHeatmapRects.node())
            else {

                chart
                    .selectAll('.impact-rects')
                    .attr('x', (d) => d.impactRectX)
                    .attr('width', (d) => d.impactRectWidth)
                    .attr('display', null)
            }
        }

        function displayMoreDetailBox(chart) {
            d3.select('.bts-barcode-prompt')
                .style('top', function (d) {
                    let svg = d3.select(chart.node().parentNode)
                    let svgProps = svg.node().getBoundingClientRect()
                    let custom = document.location.host.includes('thechangecompass') ? -15 : 0
                    let top = (svgProps.top + window.scrollY + svgProps.height / 2 + custom).toString() + 'px'
                    return top
                })
                .style('font-family', attrs.defaultFont)
                .style('opacity', 1)
        }

        function getCustomRange(node) {
            let data = d3.select(node).data()[0]
            let start = getDayMonthYear(data[0])
            let end = getDayMonthYear(data[data.length - 1])
            return { start: start, end: end }
        }

        function getCenterTextValue(node) {
            let data = d3.select(node).data()[0]
            let start = getDayMonthYear(data[0])
            let end = getDayMonthYear(data[data.length - 1])
            return start + ' - ' + end
        }

        function getDayMonthYear(date) {
            let day = date.getDate()
            let month = date.toLocaleString('default', { month: 'long' })
            let year = date.getFullYear()

            return day + ' ' + month + ' ' + year
        }

        function changeCenterTextValue(chart, newText) {
            let text = chart.select('.center-text').text(newText)
            if (text.empty()) {
                return
            }
            d3.select(text.node().parentNode).attr('transform', function (d) {
                let infoX = getInfoButtonX()
                let previousX = getPreviousButtonX()
                let previousWidth = getPreviousButtonWidth()

                let previousButtonPosition = previousX + previousWidth

                let x = previousButtonPosition + (infoX - previousButtonPosition - d3.select(this).node().getBoundingClientRect().width) / 2

                let y = calc.chartHeight + 38
                return 'translate(' + x + ',' + y + ')'
            })
        }

        function getInfoButtonX() {
            let infoButtonGroup = d3.select('.info-button-group')

            let transform = infoButtonGroup.attr('transform')
            let translate = transform.substring(transform.indexOf('(') + 1, transform.indexOf(')')).split(',')
            let infoX = parseFloat(translate[0])
            return infoX
        }

        function getPreviousButtonX() {
            let previousButtonGroup = d3.select('.previous-button-group')

            let transform = previousButtonGroup.attr('transform')
            let translate = transform.substring(transform.indexOf('(') + 1, transform.indexOf(')')).split(',')
            let previousX = parseFloat(translate[0])
            return previousX
        }

        function getPreviousButtonWidth() {
            let previousButtonGroup = d3.select('.previous-button-group')
            let previousButtonWidth = previousButtonGroup.node().getBoundingClientRect().width
            return previousButtonWidth
        }

        function increaseRectSize(node) {
            let rectElement = d3.select(node)
            let widthIncrease = 1.5
            let heightIncrease = 1.3

            bringToTop(node)

            rectElement
                .transition()
                .duration(300)
                .attr('height', (d) => attrs.rect.height * heightIncrease)
                .attr('width', (d) => calc.rectWidth * widthIncrease)
                .attr('x', function (d) {
                    var widthChange = (calc.rectWidth * widthIncrease - calc.rectWidth) / 2
                    return d.data_x - widthChange
                })
                .attr('y', function (d) {
                    var heightChange = (attrs.rect.height * heightIncrease - attrs.rect.height) / 2
                    return d.data_y - heightChange
                })
        }

        function rectUnhoverAction(chart, node) {
            hideMoreDetailBox()
            decreaseRectSize(node)
            changeCenterTextValue(chart, unhoverCenterText(chart))
            unhighlightImpactRange(chart)
            if (
                chart
                    .selectAll('.heatmap-rects')
                    .filter(function (d) {
                        return d3.select(this).attr('data-clicked')
                    })
                    .size() == 0
            )
                shortImpactNames(chart)

            showRatingStar(chart)
        }

        function showRatingStar(chart) {
            const selection = chart.selectAll('.impact-stars')
            if (selection.size() > 0) {
                selection.each(function (d, i) {
                    bringToTop(d3.select(this).node())
                })
                //console.log('chart.selectAll(\'.impact-stars\')', chart.selectAll('.impact-stars'))
               /// bringToTop(chart.selectAll('.impact-stars').node())
            }
        }

        function unhoverCenterText(chart) {
            let clickedRects = chart.selectAll('.heatmap-rects').filter(function (d) {
                return d3.select(this).attr('data-clicked')
            })

            if (clickedRects.size() > 0) return getCenterTextValue(clickedRects.node())
            return ''
        }

        function rectClickAction(chart, node, d) {
            if (chart.select('.close-button-group').empty()) {
                chart.datum().render()
            }

            showAdditionalInfo(chart, d)

            if (d3.select(node).attr('data-clicked')) {
                d3.select(node).attr('data-clicked', null)
                rectUnhoverAction(chart, node)
                shortImpactNames(chart)
            } else {
                let clickedRects = chart.selectAll('.heatmap-rects').filter(function (d) {
                    return d3.select(this).attr('data-clicked')
                })

                if (clickedRects.size() > 0) rectUnhoverAction(chart, clickedRects.node())
                clickedRects.attr('data-clicked', null)
                d3.select(node).attr('data-clicked', true)
                rectHoverAction(chart, node)
                fullImpactNames(chart)
            }

            try {
                chart.datum().showAll()
            } catch (e) {
                console.error(e)
            }

        }

        function fullImpactNames(chart) {
            chart.selectAll('.impact-texts').text((d) => (d.customImpactName ? d.customImpactName : d.ImpactName))
        }

        function shortImpactNames(chart) {
            chart.selectAll('.impact-texts').text(function (d) {
                return d3.select(this).attr('data-text')
            })
        }

        function showAdditionalInfo(chart, d) {
            chart.selectAll('.icon-buttons').attr('display', null)

            chart.select('.impact-grid-container').attr('display', null)

            let svg = d3.select(chart.node().parentNode).attr('height', function (d) {
                return chart.node().getBoundingClientRect().height + attrs.marginTop
            })
        }

        function hideMoreDetailBox() {
            d3.select('.bts-barcode-prompt').style('opacity', 0)
        }

        function decreaseRectSize(node) {
            var rectElement = d3.select(node)

            rectElement
                .transition()
                .duration(300)
                .attr('height', (d) => attrs.rect.height)
                .attr('width', (d) => calc.rectWidth)
                .attr('x', (d) => d.data_x)
                .attr('y', (d) => d.data_y)
        }

        function getDatesArray() {
            let minMaxDate = getTimeDomain()
            let min = minMaxDate[0]
            let max = minMaxDate[1]

            let daysOfYear = []

            for (let d = min; d <= max; d.setDate(d.getDate() + 1)) {
                daysOfYear.push(new Date(d))
            }

            return daysOfYear
        }

        function getSubArray(array) {
            var subArray = []
            for (let i = 0; i < array.length; i += 7) subArray.push(array.slice(i, i + 7))
            return subArray
        }

        function getTimeDomain() {
            let date = attrs.filterDates[0]
            let startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
            let startDate = getCustomStartDate(startOfMonth)

            let endDate = endOfWeek(attrs.filterDates[1], { weekStartsOn: 1 })

            return [startDate, endDate]
        }

        function getCustomStartDate(startOfMonth) {
            let monthStartDay = startOfMonth.getDay()
            let substractDay = monthStartDay === 0 ? 7 : monthStartDay - 1
            startOfMonth.setDate(startOfMonth.getDate() - substractDay)
            return startOfMonth
        }

        function processBubble(record, groupByColumn) {
            let divisions = []
            let subdivisions = []
            let subdivisionParents = []
            let teams = []
            let teamParents = []
            let geographies = []
            let activities = []
            let stakeholders = []
            const ImpactDateFroms = new Set()
            const ImpactDateTos = new Set()
            let impactCalculatedSize = 0
            let impactTypes = []
            let PartnerStakeholder = []
            let Region = []

            let impacts = record.Impacts.map(function (d) {
                impactCalculatedSize += getCalculatedImpactSize(d)
                const divisionFiltered = get(attrs, 'graphFilters.filters.division', []).map((i) => i.Name)
                if (!isEmpty(divisionFiltered)) {
                    divisions = divisions.concat(d.DivisionNames.filter((i) => divisionFiltered.includes(i)))
                } else {
                    divisions = divisions.concat(d.DivisionNames)
                }


                const subDivisionFiltered = get(attrs, 'graphFilters.filters.subDivision', []).map((i) => i.Name)
                if (!isEmpty(subDivisionFiltered)) {
                    subdivisions = subdivisions.concat(d.SubDivisionNames.filter((i) => subDivisionFiltered.includes(i)))
                } else {
                    subdivisions = subdivisions.concat(d.SubDivisionNames)
                }
                const teamFiltered = get(attrs, 'graphFilters.filters.team', []).map((i) => i.Name)
                if (!isEmpty(teamFiltered)) {
                    teams = teams.concat(d.TeamNames.filter((i) => teamFiltered.includes(i)))
                } else {
                    teams = teams.concat(d.TeamNames)
                }

                subdivisionParents = subdivisionParents.concat(d.SubDivisionNamesParents)

                teamParents = teamParents.concat(d.TeamNamesParents)
                geographies = geographies.concat(d.Geographies)
                activities = activities.concat(d.Activities)
                stakeholders = stakeholders.concat(d.Stakeholders)
                PartnerStakeholder = PartnerStakeholder.concat(d.PartnerStakeholder)
                Region = Region.concat(d.Region)
                ImpactDateFroms.add(d.ImpactFromDate)
                ImpactDateTos.add(d.ImpactToDate)
                impactTypes = impactTypes.concat(d.ImpactType)

                const [daySizes, total] = getImpactDaySizes(d)
                return {
                    ...d,
                    ImpactType: d.ImpactType + ' Impact',
                    Type: d.ImpactType + ' Impact',
                    ImpactDaySizes: daySizes,
                    totalSize: total,
                }
            })

            /// recommends section  for each Recommend Dates new line in executive summary

            const newImpacts = (attrs.data.recommendImpacts || [])
                .filter((i) => i.InitiativeId === record.InitiativeId)
                .map((i) => {
                    const impact = { ...(find(impacts, { ImpactName: i.Name }) || {}), ...i }
                    if (!impact) return null
                    return i.RecommendDates.map((range, index) => {
                        let newImpact = {
                            ...impact,
                            ImpactFromDate: formatISO(range.From, { representation: 'date' }) + 'T00:00:00',
                            ImpactToDate: formatISO(range.To, { representation: 'date' }) + 'T00:00:00',
                            From: range.From,
                            To: range.To,
                        }
                        ImpactDateFroms.add(newImpact.ImpactFromDate)
                        ImpactDateTos.add(newImpact.ImpactToDate)
                        const [daySizes, total] = getImpactDaySizes(newImpact)
                        impactCalculatedSize += getCalculatedImpactSize(newImpact)
                        return {
                            ...newImpact,
                            ImpactName: newImpact.ImpactName + ' (Recommended)',
                            ImpactDaySizes: daySizes,
                            totalSize: total,
                            recommended: true,
                            recommendedFirstDate: index === 0,
                        }
                    })
                })
                .filter((i) => i)
                .flat()
            /*

            const newImpacts = (attrs.data.recommendImpacts||[])
                .filter((i) => i.InitiativeId === record.InitiativeId)
                .map((i) => {
                    const impact = {...(find(impacts, {ImpactName: i.Name}) || {}), ...i}
                    if (!impact) return null
                    let totalDaySizes = {}
                    let impactTotal = 0
                    let froms = []
                    let tos = []
                    i.RecommendDates.forEach((range) => {
                        const newImpact = {
                            ...impact,
                            ImpactFromDate: formatISO(range.From, { representation: 'date' })+'T00:00:00',
                            ImpactToDate: formatISO(range.To, { representation: 'date' })+'T00:00:00',
                            From: range.From,
                            To: range.To
                        }
                        froms = [...froms, range.From]
                        tos = [...tos, range.To]
                        ImpactDateFroms = ImpactDateFroms.concat(newImpact.ImpactFromDate);
                        ImpactDateTos = ImpactDateTos.concat(newImpact.ImpactToDate);
                        const [daySizes, total] = getImpactDaySizes(newImpact)
                        totalDaySizes = merge(totalDaySizes, daySizes) ///[...totalDaySizes, ...daySizes]
                        impactTotal += total
                        impactCalculatedSize += getCalculatedImpactSize(newImpact);
                    })
                    const from = d3.min(froms)
                    const to = d3.max(froms)
                    return {
                        ...impact,
                        ImpactName: impact.ImpactName + ' (Recommended)',
                        ImpactFromDate: formatISO(from, { representation: 'date' })+'T00:00:00',
                        ImpactToDate: formatISO(to, { representation: 'date' })+'T00:00:00',
                        From: from,
                        To: to,
                        ImpactDaySizes: totalDaySizes,
                        totalSize: impactTotal,
                        recommended: true
                    }
                }).filter((i) => i).flat()
 */
            ///groupBy(newImpacts, )
            impacts = [...impacts, ...newImpacts]
            /// end of recommends section

            ///attrs.allImpactTypes = attrs.allImpactTypes.concat(impactTypes);
            //attrs.allImpactDates = attrs.allImpactDates.concat(ImpactDateFroms.filter((d) => d)).concat(ImpactDateTos.filter((d) => d));

            const [InitiativeDaySizes, dayMaxSize] = getInitiativeDaySizes({ ...record, Impacts: impacts })
            return {
                ...record,
                Impacts: impacts,
                dayMaxSize: dayMaxSize,
                maxImpactDate: d3.max(impacts.map((x) => new Date(x.ImpactToDate))),
                mimImpactDate: d3.min(impacts.map((x) => new Date(x.ImpactFromDate))),
                maxImpactSize: d3.max(impacts.map((x) => x.totalSize)),
                InitiativeDaySizes: InitiativeDaySizes,
                Divisions: uniq(divisions.filter((d) => d)),
                SubDivisions: subdivisions.filter((d) => d),
                SubDivisionParents: subdivisionParents.filter((d) => d),
                Teams: teams.filter((d) => d),
                TeamParents: teamParents.filter((d) => d),
                Geographies: geographies.filter((d) => d),
                Activities: activities.filter((d) => d),
                Stakeholders: stakeholders.filter((d) => d),
                ImpactDateFroms: Array.from(ImpactDateFroms).filter((d) => d),
                ImpactDateTos: Array.from(ImpactDateTos).filter((d) => d),
                impactCalculatedSize: impactCalculatedSize,
                PartnerStakeholder: PartnerStakeholder,
                ImpactType: impactTypes,
                Region: Region,
                Type: record.ChangeTypes,
            }
        }

        function getNestedData() {
            ///attrs.data.allImpacts = {};
            const groupByColumn = getGroupByColumnKey(attrs.graphFilters.type)
            let groupedData = {}

            const groupType = attrs?.graphFilters?.type;
            attrs.data.bubbles = ((attrs.data || { bubbles: [] }).bubbles || [])
                //.filter((x) => x.ImpactSize > 0)
                .map(function (d) {
                    const record = processBubble(d, groupByColumn)
                    record.leftStrategies = uniq(
                        (groupByColumn.type === 'array' ? record[groupByColumn.name] || [] : [(record[groupByColumn.name] || '').toString()]).filter((x) => x)
                    )
                    if (record.leftStrategies.length == 0) record.leftStrategies.push(groupByColumn.name == '' ? groupByColumn.name : 'Without ' + groupByColumn.name.split(/(?=[A-Z])/).join(' '))
                    record.firstStrategy = record.leftStrategies[0]
                    //attrs.data.allImpacts[record.InitiativeId] = record.Impacts
                    record.leftStrategies.forEach((i) => {
                        if (Object.prototype.hasOwnProperty.call(groupedData, i)) {
                            groupedData[i].values.push(record)
                        } else {
                            groupedData[i] = { key: i, values: [record] }
                        }
                    })
                    return record
                })

            /*attrs.data.allRecommendImpacts = fromPairs((attrs.data.recommendImpacts||[]).map((i) => {
                const impact = {...(find(attrs.data.allImpacts[i.InitiativeId], {ImpactName: i.Name}) || {}), ...i}
                if (!impact) return null
                const recommended = i.RecommendDates.map((range) => {
                    let newImpact = {...impact, ImpactFromDate: range.From, ImpactToDate: range.To}
                    const [daySizes, total] = getImpactDaySizes(impact)
                    return {...newImpact, daySizes, total}
                })

                attrs.data.allImpacts[i.InitiativeId] = [...attrs.data.allImpacts[i.InitiativeId],  ...recommended]
                const [daySizes, total] = getImpactDaySizes(impact)

                return [
                    i.Id,
                    {...impact, daySizes, total}
                ]
            }).filter((i) => i))*/
            // attrs.allImpactDates.concat(attrs.filterDates);
            /// attrs.allImpactTypes = Array.from(new Set(attrs.allImpactTypes));
            const nestedData = Object.values(groupedData).sort(function (a, b) {
                return b.values.length - a.values.length
            })
            attrs.keysByIndex = {}
            attrs.centeredByIndex = {}
            attrs.lastByIndex = {}
            let previousIndex = 0
            let sortedData = []
            attrs.strategyKeys = []

            let sortedNestedData = sortByCaseSensitive(nestedData, 'key');
            if(groupType === 'Priority'){
                sortedNestedData = sortByPriority(nestedData, 'key');
                // sortedNestedData = nestedData.sort((a, b) => {
                //     const keyA = isNaN(a.key) ? a.key : parseInt(a.key, 10);
                //     const keyB = isNaN(b.key) ? b.key : parseInt(b.key, 10);
                  
                //     // If both keys are numeric
                //     if (!isNaN(keyA) && !isNaN(keyB)) {
                //       return keyA - keyB;
                //     }
                  
                //     // If keyA is numeric and keyB is not, keyA comes first
                //     if (!isNaN(keyA) && isNaN(keyB)) {
                //       return -1;
                //     }
                  
                //     // If keyA is not numeric and keyB is, keyB comes first
                //     if (isNaN(keyA) && !isNaN(keyB)) {
                //       return 1;
                //     }
                  
                //     // If both keys are non-numeric, compare as strings
                //     if (keyA < keyB) return -1;
                //     if (keyA > keyB) return 1;
                //     return 0;
                //   });
            }
            const slice = sortedNestedData.map(function (d, nestedIndex) {
                attrs.strategyKeys.push(d.key)
                const sortByName = function (a, b) {
                    if (a.InitiativeName.trim() < b.InitiativeName.trim()) {
                        return -1
                    }
                    if (a.InitiativeName.trim() > b.InitiativeName.trim()) {
                        return 1
                    }
                    return 0
                }
                const sortByTime = (a, b) => new Date(a.mimImpactDate) - new Date(b.mimImpactDate)
                const values = d.values
                    .sort(attrs.graphFilters.sortByTime === true ? sortByTime : sortByName)
                    .map(function (value, valueIndex) {
                        value.centeredInitiative = false
                        value.lastStrategyInitiative = false
                        attrs.keysByIndex[previousIndex] = d.key
                        if (valueIndex === parseInt(d.values.length / 2)) attrs.centeredByIndex[previousIndex] = d.key
                        if (valueIndex === d.values.length - 1 && nestedIndex !== nestedData.length - 1) attrs.lastByIndex[previousIndex] = d.key
                        previousIndex += 1
                    })
                sortedData = sortedData.concat(d.values)
                return { ...d, values }
            })
            return {
                nested: slice,
                sorted: sortedData,
            }
        }

        function filterImpactsByColumnKey(chosenColumn, initiave, groupValue) {
            switch (chosenColumn) {
                case 'Division':
                    return { ...initiave, Impacts: initiave.Impacts.filter((impact) => (impact.Division||[]).includes(groupValue)) }
                case 'Geography':
                    if (attrs.graphFilters.currentImpactType === 'Employee')
                        return {
                            ...initiave,
                            Impacts: initiave.Impacts.filter((impact) => (impact.Geographies||[]).includes(groupValue))
                        }
                    return {
                        ...initiave,
                        Impacts: initiave.Impacts.filter((impact) => (impact.Region||[]).includes(groupValue))
                    }
                case 'Activity':
                    return {
                        ...initiave,
                        Impacts: initiave.Impacts.filter((impact) => (impact.Activities||[]).includes(groupValue))
                    }
                case 'Stakeholder':
                    if (attrs.graphFilters.currentImpactType === 'Employee')
                        return {
                            ...initiave,
                            Impacts: initiave.Impacts.filter((impact) => (impact.Stakeholders||[]).includes(groupValue))
                        }
                    return {
                        ...initiave,
                        Impacts: initiave.Impacts.filter((impact) => (impact.PartnerStakeholder||[]).includes(groupValue))
                    }
                default:
                    return initiave
            }
        }

        function getGroupByColumnKey(chosenColumn) {
            switch (chosenColumn) {
                case 'Division':
                    return { name: 'Divisions', type: 'array' }
                case 'Phase':
                    return { name: 'InitiativePhaseName', type: 'string' }
                case 'Geography':
                    if (attrs.graphFilters.currentImpactType === 'Employee')
                        return { name: 'Geographies', type: 'array' }
                    return { name: 'Region', type: 'array' }
                case 'Activity':
                    return { name: 'Activities', type: 'array' }
                case 'Stakeholder':
                    if (attrs.graphFilters.currentImpactType === 'Employee')
                        return { name: 'Stakeholders', type: 'array' }
                    return { name: 'PartnerStakeholder', type: 'array' }
                case 'Type':
                    return { name: 'Type', type: 'array' }
                case 'Owner':
                    return { name: 'Owner', type: 'string' }
                case 'Priority':
                    return { name: 'Priority', type: 'string' }
                case 'Strategy':
                    return { name: 'Strategy', type: 'array' }
                default:
                    return { name: '', type: 'array' }
            }
        }

        function addMonthAxis() {
            let monthAxisHeight = 25
            let svgHeight = monthAxisHeight + calc.chartTopMargin

            axisContainer.selectAll('.month-axis-svg').remove()

            //Add svg
            let svg = axisContainer
                .insert('svg', ':first-child')
                .classed('month-axis-svg', true)
                .attr('width', heatmapBlockWidth)
                // .attr('width', '10%')
                .attr('height', svgHeight)
                .attr('id', 'month-axis-svg')
                .attr('font-family', attrs.defaultFont)
                .style('overflow', 'visible')
                .attr('transform', function (d) {
                   // let containerWidth = container.node().getBoundingClientRect().width - 100
                    let x = initiativeAreaWidth + 20 ///containerWidth / 9 + (containerWidth - 1100) / 8
                    let y = 0
                    return 'translate(' + x + ',' + y + ')'
                })

            //Add container g element
            let chart = svg.patternify({ tag: 'g', selector: 'chart' }).attr('transform', 'translate(' + calc.chartLeftMargin + ',' + calc.chartTopMargin + ')')
            //const mDiff = differenceInMonths(attrs.filterDates[1] || new Date(), attrs.filterDates[0] || new Date())

            let monthAxis = d3
                .axisBottom(timeScale)
                .ticks(monthTicks)
                .tickFormat((d) => d.toLocaleString('default', { month: 'short' }).toUpperCase())

            let monthAxisGroup = chart
                .patternify({ tag: 'g', selector: 'month-axis-group' })
                /*.attr('transform', function (d) {
                    var x = 35;
                    var y = 0;
                    return 'translate(' + x + ',' + y + ')';
                })*/
                .call(monthAxis)

            let monthAxisTexts = monthAxisGroup
                .selectAll('text')
                .attr('dx', '0.3em')
                .attr('font-size', '14px')
                .attr('fill', 'gray')
                .attr('font-family', attrs.defaultFont)

            monthAxisGroup.selectAll('line').remove()
            monthAxisGroup.selectAll('path').remove()
        }

        function addStrategyLine(verticalLabel, nextRowLabel) {
            let lineContainer = container
                .append('div')
                .style('position', 'relative')
                .classed('strategy-line-container', true)
                .style('padding', '5px 0 20px 0')

            let line = lineContainer
                .append('hr')
                .style('background-color', 'gray')
                .classed('row-separator-hr', true)
                .style('height', '1px')
                .style('width', '100%')
                //.style('margin', '40px 0 40px 0')

            //const rowValues = groupedByStrategy.nested.find((x) => x.key == verticalLabel)
            //let currentRowValuesCount = rowValues ? rowValues.values.length : 1
            //const rowLabels = groupedByStrategy.nested.find((x) => x.key == nextRowLabel)
            //let nextRowValuesCount = rowLabels ? rowLabels.values.length : 1
           // if ((verticalLabel||[]).length > 12 && currentRowValuesCount === 1) line.style('margin-top', '50px')
           // if ((nextRowLabel||[]).length > 12 && nextRowValuesCount === 1) line.style('margin-bottom', '50px')
        }

        function addCompanyStrategyName(currentInitiative, index) {
            let headerContainer = container
                .append('div')
                .style('position', 'relative')
                //.style('top', '40px')
                .classed('strategy-header', true)

            ///(attrs.execSummaryData||[]).length
            headerContainer
                .append('p')
                .text(function (d) {
                    return attrs.keysByIndex[index]
                })
                .style('font-size', '14px')
                .style('position', 'absolute')
                //.style('transform', 'rotate(-90deg)')
                .style('top', function (d, i) {
                    /*let valuesCount = groupedByStrategy.nested.find((x) => x.key == attrs.keysByIndex[index]).values.length
                    sliceStrategyText(attrs.keysByIndex[index], valuesCount, this)
                    let y = 0
                    if (valuesCount > 1 && valuesCount % 2 == 0) y -= 10
                    if (valuesCount > 1 && valuesCount % 2 == 1) y += 5
                    headerContainer.style('top', (y > 0 ? y : 0) + 'px')
                    return y + 'px'
                     */
                    return '-20px'
                })
                .style('left', function (d) {
                    /*let height = this.getBoundingClientRect().height
                    let leftDistance = -height / 3
                    let shownKey = attrs.keysByIndex[index]
                    if (height > 50) leftDistance -= (height - 50) / 6
                    if (shownKey.length < 5) leftDistance += 5 - shownKey.length
                    return leftDistance + 'px'*/
                    return '0px'
                })
        }

        function sliceStrategyText(label, count, node) {
            let text = d3.select(node).text()
            let maxCharacters = label.length >= 15 && count === 1 ? 10 : 12

            let sliceLimit = count == 1 ? maxCharacters : maxCharacters + (count - 1) * 4

            if (text.length > sliceLimit) d3.select(node).text(text.slice(0, sliceLimit) + '..')
        }

        /*function addGradient() {
            const gradientWidth = 200
            const gradientHeight = 15
            const svgWidth = gradientWidth + calc.chartLeftMargin
            const svgHeight = gradientHeight + calc.chartTopMargin

            axisContainer.selectAll('.gradient-svg').remove()

            const gradientSvg = axisContainer
                .insert('svg', ':first-child')
                .classed('gradient-svg', true)
                .attr('width', svgWidth)
                .attr('height', svgHeight)
                .attr('id', 'gradient-svg')
                .attr('font-family', attrs.defaultFont)
                .style('overflow', 'visible')
                .style('right', '-30%')
                .style('position', 'relative')
                .classed('hidden-print', true)

            addGradientDefs(gradientSvg)

            //Add container g element
            const gradientGroup = gradientSvg
                .patternify({ tag: 'g', selector: 'chart' })
                .attr('transform', 'translate(' + calc.chartLeftMargin + ',' + calc.chartTopMargin + ')')

            gradientGroup
                .patternify({ tag: 'rect', selector: 'gradient-rect' })
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', gradientWidth)
                .attr('height', gradientHeight)
                .style('fill', 'url(#linear-gradient)')

            gradientGroup
                .patternify({ tag: 'text', selector: 'lowest-impact' })
                .attr('x', 0)
                .attr('y', -gradientHeight / 3)
                .attr('font-size', '10px')
                .attr('text-anchor', 'middle')
                .style('fill', 'gray')
                .text('minimum impact')

            gradientGroup
                .patternify({ tag: 'text', selector: 'highest-impact' })
                .attr('x', gradientWidth)
                .attr('y', -gradientHeight / 3)
                .attr('font-size', '10px')
                .attr('text-anchor', 'middle')
                .style('fill', 'gray')
                .text('maximum impact \u00A0 \u00A0 \u00A0')

            gradientSvg.style('transform', function (d) {
                let x = -this.getBoundingClientRect().width - 5 + 'px'
                let y = -30 + 'px'
                return 'translate(' + x + ',' + y + ')'
            })
        }

        function addGradientDefs(gradientSvg) {
            let linearGradient = gradientSvg.append('defs').append('linearGradient').attr('id', 'linear-gradient')
            linearGradient.append('stop').attr('offset', '12%').attr('stop-color', attrs.colorRange[0])
            linearGradient.append('stop').attr('offset', '24%').attr('stop-color', attrs.colorRange[1])
            linearGradient.append('stop').attr('offset', '44%').attr('stop-color', attrs.colorRange[2])
            linearGradient.append('stop').attr('offset', '64%').attr('stop-color', attrs.colorRange[3])
            linearGradient.append('stop').attr('offset', '80%').attr('stop-color', attrs.colorRange[4])
            linearGradient.append('stop').attr('offset', '90%').attr('stop-color', attrs.colorRange[5])
        }*/

        function getColorDomain(value) {
            let domainPiece = value / 7
            let domainArray = []

            for (let i = 1; i <= 7; i++) domainArray.push(i * domainPiece)

            return domainArray
        }

        function setCurrentScenarioImpacts() {
            if (!isEmpty(attrs.graphFilters.Scenarios)) {
                /// execSummaryData
                const scenarioID = attrs.graphFilters.Scenarios.Id
                const initiatives = groupBy(attrs.SavedScenarios || [], 'Id')[scenarioID] || []
                attrs.execSummaryData = attrs.execSummaryData.map((i) => {
                    const scenario = find(initiatives, { InitiativeId: i.InitiativeId })
                    if (isEmpty(scenario)) {
                        return { ...i }
                    }
                    const currentInitiative = { ...i }
                    const dateDiff =  differenceInDays(scenario.DateStart, currentInitiative.mimImpactDate)
                    changeImpactDates(currentInitiative, dateDiff, false)
                    return { ...currentInitiative }
                })
            }
            /*if (isEmpty(attrs.graphFilters.Scenarios)) {
                attrs.data.bubbles.forEach(function (initiativeData) {
                    initiativeData.Impacts = attrs.data.allImpacts[initiativeData.InitiativeId].filter((impact) => !impact.ScenarioId);
                });
            } else {
                const scenarioID = attrs.graphFilters.Scenarios.Id;

                /// old code.
                attrs.data.bubbles.forEach(function (initiativeData) {
                    const initiativeAllImpacts = attrs.data.allImpacts[initiativeData.InitiativeId];
                    const currentScenarioImpacts = initiativeAllImpacts.filter((impact) => impact.ScenarioId == scenarioID);

                    if (currentScenarioImpacts.length > 0) initiativeData.Impacts = currentScenarioImpacts;
                    else initiativeData.Impacts = initiativeAllImpacts.filter((impact) => !impact.ScenarioId);
                });

                const savedInitiatives = groupBy(attrs.SavedScenarios||[], 'Id')[scenarioID]||[]
            }*/
        }

        function getInitiativeDaySizes(record) {
            let minDate = d3.min(record.Impacts.map((x) => new Date(x.ImpactFromDate)))
            let maxDate = d3.max(record.Impacts.map((x) => new Date(x.ImpactToDate)))
            if (!minDate || !maxDate) return {}

            minDate.setHours(0, 0, 0, 0)
            maxDate.setHours(0, 0, 0, 0)

            let daysJson = {}
            let daysArray = []

            for (var d = minDate; d <= maxDate; d.setDate(d.getDate() + 1)) {
                let initiativeOneDaySize = getImpactSizeSumForTheDay(record, d)
                daysJson[formatDate(d)] = initiativeOneDaySize
                daysArray.push(initiativeOneDaySize)
            }

            record.dayMaxSize = d3.max(daysArray)

            return [daysJson, d3.max(daysArray)]
        }

        function getImpactSizeSumForTheDay(record, d) {
            return d3.sum(record.Impacts.filter((x) => x.ImpactDaySizes[formatDate(d)] != undefined).map((x) => x.ImpactDaySizes[formatDate(d)]))
        }

        function getImpactDaySizes(d) {
            let daysJson = {}
            let start = new Date(d.ImpactFromDate)
            let end = new Date(d.ImpactToDate)

            start.setHours(0, 0, 0, 0)
            end.setHours(0, 0, 0, 0)

            let eachDaySize =
                attrs.data.LevelTimeRange.find((x) => x.Level == d.ImpactLevel).Avg / 5

            let total = 0

            for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
                total += eachDaySize
                daysJson[formatDate(d)] = eachDaySize
            }

            d.totalSize = total

            return [daysJson, total]
        }

        function formatDate(date, yearIncrease) {
            return formatISO(yearIncrease ? addYears(date, 1) : date, { representation: 'date' })
        }

        function getCalculatedImpactSize(d) {
            let start = getStartDate(d)
            let end = getEndDate(d)
            let avg = attrs.data.LevelTimeRange.find((x) => x.Level == d.ImpactLevel).Avg
            let diff = datediff(start, end) + 1
            if (diff < 0) return 0
            return (avg * diff) / 5
        }

        function getStartDate(d) {
            return new Date(d.ImpactFromDate)
        }

        function getEndDate(d) {
            return new Date(d.ImpactToDate)
        }

        function datediff(first, second) {
            // Take the difference between the dates and divide by milliseconds per day.
            // Round to nearest whole number to deal with DST.
            return Math.round((second - first) / (1000 * 60 * 60 * 24))
        }

        // Smoothly handle data updating
        updateData = function () {}

        //#########################################  UTIL FUNCS ##################################
    }

    //----------- PROTOTYPE FUNCTIONS  ----------------------
    d3.selection.prototype.patternify = function (params) {
        const container = this
        const selector = params.selector
        const elementTag = params.tag
        const data = params.data || [selector]

        // Pattern in action
        const selection = container.selectAll('.' + selector).data(data, (d, i) => {
            if (typeof d === 'object') {
                if (d.id) {
                    return d.id
                }
            }
            return i
        })
        selection.exit().remove()
        return selection.enter().append(elementTag).merge(selection).attr('class', selector)
    }

    const self = this
    //Dynamic keys functions
    Object.keys(attrs).forEach((key) => {
        // Attach variables to main function
        this[key] = function (value) {
            if (!arguments.length) return attrs[key]
            attrs[key] = value
            return self
        }

        /*return (main[key] = function (_) {
            var string = `attrs['${key}'] = _`;
            if (!arguments.length) {
                return eval(` attrs['${key}'];`);
            }
            attrs[key] = _
            ///eval(string);
            return main;
        });*/
    })

    //Set attrs as property
    this.attrs = attrs

    //Exposed update functions
    this.data = function (value) {
        if (!arguments.length) return attrs.data
        attrs.data = value
        if (typeof updateData === 'function') {
            updateData()
        }
        return self
    }

    this.updateAttributes = (value) => {
        attrs = { ...attrs, ...value }
        return self
    }
    // Run  visual
    this.render = function () {
        main()
        return self
    }

    return this
}
